import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

const ButtonLinkWithLeadingIcon = ({ label, icon, type, href }) => {
  return (
    <>
      <RouterLink
        to={href}
        className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {React.cloneElement(icon, {
          className: "-ml-0.5 h-5 w-5",
        })}
        {label}
      </RouterLink>
    </>
  );
};

export default ButtonLinkWithLeadingIcon;

ButtonLinkWithLeadingIcon.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  icon: PropTypes.element.isRequired,
};

ButtonLinkWithLeadingIcon.defaultProps = {
  type: "button",
};
