import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ComputerIcon } from "../../images/icons/computer.svg";
const VacancyIntro = ({ data }) => {
  const { t } = useTranslation(["vacancyIntro", "swiperFacts"]);
  return (
    <div className="flex flex-col h-full w-full  mt-12 ">
      <div id="title" className="text-2xl font-semibold mb-4">
        {t("welcome")}
      </div>
      <div id="description" className="text-lg">
        {t("introText")}
      </div>
      <div
        id="jobInfo"
        className="flex flex-grow  h-full  flex-col items-center justify-center p-2 mt-36 gap-y-6"
      >
        <ComputerIcon className="h-14 w-auto" />
        <div id="jobTitle" className="text-2xl font-semibold mb-2">
          {data?.job_title}
        </div>
        <div>
          <svg
            width="31"
            height="2"
            viewBox="0 0 31 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H30"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div id="jobScope" className="text-lg">
          {t("hardFacts.workingType.options." + data?.working_type, {
            ns: "swiperFacts",
          })}
        </div>
      </div>
    </div>
  );
};

export default VacancyIntro;
