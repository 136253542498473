import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import Login from "./Pages/Auth/Login";
import Dashboard from "./Pages/Dashboard/Index";
import JobAd from "./Pages/JobAds/Create";
import { default as EditJobAd } from "./Pages/JobAds/Edit";
import JobAds from "./Pages/JobAds/Index";
import HRLayout from "./Pages/Layouts/HRLayout";
import VacancyFlowPage from "./Pages/VacancyFlowPage/Index";
import { default as SwipeProfiles } from "./Pages/Vacancies/Show";
import Vacancy from "./Pages/Vacancies/Create";
import Video from "./Pages/Video/Index";
import Videofr from "./Pages/Video/Videofr";
import Videoselector from "./Pages/Video/Videoselector";

import Directions from "./Pages/Vacancies/Directions";
import CreateAd from "./Pages/JobAds/CreateAd";
import SwipeDirections from "./Pages/Vacancies/SwipeDirections";

const Router = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/vacancy/:hash">
          <Route index element={<SwipeDirections />} />
          <Route path="persona" element={<SwipeProfiles />} />
        </Route>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/vacancy">
            <Route index element={<Directions showLayout />} />
            <Route path="create" element={<Vacancy />} />
          </Route>
          <Route element={<HRLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/job-ads">
              <Route index element={<JobAds />} />
              <Route path="create" element={<JobAd />} />
              <Route path=":id">
                <Route index element={<EditJobAd />} />
                <Route path="create" element={<CreateAd />} />
              </Route>
            </Route>
             <Route path="/videopreview" element={<Video />} />
             <Route path="/videopreview2" element={<Videofr />} />
             <Route path="/videopreview3" element={<Videoselector />} /> 
          </Route>
        </Route>
        <Route
          path="/vacancy/:hash/requirements"
          element={<VacancyFlowPage />}
        />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default Router;

const ProtectedRoutes = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Outlet />
    </GoogleOAuthProvider>
  );
};
