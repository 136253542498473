import React from "react";

const AttributeBatch = ({ label, icon }) => {
  return (
    <span className="border border-em-primary rounded-full px-4 py-2 text-em-primary text-sm ">
      {label}
    </span>
  );
};

export default AttributeBatch;
