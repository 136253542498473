import { HeartIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VacancyLayout from "../Layouts/VacancyLayout";

const SwipeDirections = () => {
  const { t } = useTranslation("vacancy", { keyPrefix: "swipeDirections" });
  const navigate = useNavigate();
  return (
    <>
      <VacancyLayout
        colorScheme={"primary"}
        onSubmit={() => navigate("persona")}
        submitButtonLabel={t("buttonLabel")}
      >
        <div className="text-white">
          <p className="whitespace-pre-line">{t("description")}</p>
          <p className="whitespace-pre-line mt-4 opacity-60">
            {t("disclaimer")}
          </p>
        </div>

        <div className="flex items-center justify-center mt-24 sm:mt-96">
          <div className="mt-8 flex items-center gap-x-12  pb-6">
            <div className="flex items-cetner justiy-center">
              <div>
                <div className="flex items-center justify-center">
                  <div className=" rounded-full bg-red-300 hover:bg-red-400 p-2 h-16 w-16 flex items-center justify-center">
                    <XMarkIcon className="h-8 w-8 text-white" />
                  </div>
                </div>
                <p className="text-white text.sm mt-4 whitespace-nowrap">
                  {t("noFit")}
                </p>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-center">
                <div className=" rounded-full bg-green-300 hover:bg-green-400 p-2 h-16 w-16 flex items-center justify-center">
                  <HeartIcon className="h-8 w-8 text-white" />
                </div>
              </div>
              <p className="text-white text.sm mt-4 whitespace-nowrap">
                {t("fit")}
              </p>
            </div>
          </div>
        </div>
      </VacancyLayout>
    </>
  );
};

export default SwipeDirections;
