import React, { useEffect, useState } from "react";
import { Editable, useEditor } from "@wysimark/react";
import Card from "../../../Components/Cards/Card";
import Spinner from "../../../Components/Loaders/Spinner";
import { useTranslation } from "react-i18next";
import ButtonWithLeadingIcon from "../../../Components/Buttons/ButtonWithLeadingIcon";
import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/solid";

const CreateTextAd = ({ createdMarkdown, onRefresh, onDownload, loading }) => {
  console.log("render");
  const { t } = useTranslation("jobAd", { keyPrefix: "createAd.textAd" });
  const [refresh, setRefresh] = useState(false);
  const [markdown, setMarkdown] = useState(createdMarkdown);
  const editor = useEditor({
    initialMarkdown: markdown,
  });
  useEffect(() => {
    setMarkdown(createdMarkdown);
    setRefresh((prev) => !prev);
  }, [createdMarkdown]);

  if (!createdMarkdown) {
    return (
      <Card>
        <div className="p-12">
          <div className="flex items-center justify-center ">
            <div className="animate-spin h-4 w-4 ">
              <Spinner />
            </div>
          </div>

          <div className="text-center mt-6">
            <p className="whitespace-pre-line">{t("loading")}</p>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <>
      <div className="flex justify-end mb-4">
        <ButtonWithLeadingIcon
          disabled={loading}
          variant={"secondary"}
          label={t("refresh")}
          icon={<ArrowPathIcon />}
          onClick={onRefresh}
        />
      </div>
      <Editable editor={editor} />
      <div className="flex justify-end mt-8">
        <ButtonWithLeadingIcon
          disabled={loading}
          variant={"secondary"}
          label={t("save")}
          icon={<ArrowDownTrayIcon />}
          onClick={onDownload}
        />
      </div>
    </>
  );
};

export default CreateTextAd;
