import React from "react";
import PropTypes from "prop-types";

const BadgeWithRemoveButton = ({ label, onRemove, color, hoverColor }) => {
  return (
    <span
      className={`inline-flex items-center gap-x-0.5 rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10 ${color}`}
    >
      {label}
      <button
        type="button"
        className={`group relative -mr-1 h-3.5 w-3.5 rounded-sm ${hoverColor}`}
        onClick={onRemove}
      >
        <span className="sr-only">Remove</span>
        <svg
          viewBox="0 0 14 14"
          className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
        >
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
        <span className="absolute -inset-1" />
      </button>
    </span>
  );
};

export default BadgeWithRemoveButton;

BadgeWithRemoveButton.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
};

BadgeWithRemoveButton.defaultProps = {
  color: "bg-gray-50 text-gray-600",
  hoverColor: " hover:bg-gray-500/20",
};
