import React from "react";
import ProfileCard from "../../../";
import PersonaCard from "../../Vacancies/PersonaComponents/PersonaCard";
import usePersonaImages from "../../../Hooks/usePersonaImages";
import { useTranslation } from "react-i18next";
import { UserIcon } from "@heroicons/react/24/solid";
const ProfileOverview = ({ profiles, summary }) => {
  const { getPersonaImage } = usePersonaImages();
  const { t } = useTranslation("jobAd", { keyPrefix: "edit.profileOverview" });
  return (
    <>
      <h2 className="font-bold text-2xl mb-4">{t("title")}</h2>

      {summary && (
        <div className="flex items-center space-x-2">
          <UserIcon className="h-4 w-4 " />
          <p>
            {summary.recipients_finished} / {summary.recipients_total}{" "}
            {" " + t("recipients")}
          </p>
        </div>
      )}
      <div className="mt-4">
        {profiles && profiles.length > 0 ? (
          <p className="whitespace-pre-line text-base">{t("description")}</p>
        ) : (
          <p className="whitespace-pre-line text-base">
            {t("descriptionNoProfiles")}
          </p>
        )}
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        {profiles &&
          profiles.map((profile, index) => (
            <PersonaCard
              persona={profile}
              key={"profile" + index}
              imagePath={getPersonaImage(profile.gender)}
            />
          ))}
      </div>
    </>
  );
};

export default ProfileOverview;
