import React, { useContext, useEffect, useState } from "react";
import VacancyIntro from "../VacancyIntro/Index";
import FullScreenComponent from "./FullScreenComponent";
import SwiperFacts from "../SwiperFacts/IndexNew";
import { useParams } from "react-router-dom";
import { vacancyFlowContext } from "./VacancyFlowContext";
import { useNavigate } from "react-router-dom";
import Directions from "../Vacancies/Directions";
import useJobAdRecipient from "../../Api/useJobAdRecipients";
import AuthContext from "../Auth/AuthContext";
import useJobQualifications from "../../Api/useJobQualifications";

const VacancyFlowPage = () => {
  let { hash } = useParams();
  const navigate = useNavigate();
  const { getJobAdRecipient } = useJobAdRecipient();
  const { auth } = useContext(AuthContext);
  const [jobAd, setJobAd] = useState(null);
  const [selectedQualifications, setSelectedQualifications] = useState([]);

  const { acceptJobQualifications } = useJobQualifications();

  const [pages, setPages] = useState([
    {
      title: "Explanation",
      content: <Directions />,
    },
    {
      title: "HR Facts",
      content: (
        <SwiperFacts
          data={jobAd}
          viaHR={!jobAd?.self_swipe}
          onQualificationSelect={setSelectedQualifications}
        />
      ),
    },
  ]);

  const handleDone = () => {
    if (selectedQualifications.length > 0) {
      acceptJobQualifications(auth.token, selectedQualifications).then(
        (data) => {
          navigate("/vacancy/" + hash);
        }
      );
    } else {
      navigate("/vacancy/" + hash);
    }
  };
  useEffect(() => {
    if (auth && auth.token) {
      getJobAdRecipient(auth.token, hash).then((data) => {
        if (data && !data.self_invited) {
          setPages((prev) => [
            {
              title: "Intro",
              content: <VacancyIntro data={data} />,
            },
            {
              title: "Explanation",
              content: <Directions />,
              colorScheme: "primary",
            },
            {
              title: "HR Facts",
              content: (
                <SwiperFacts
                  data={data}
                  viaHR={!data?.self_invited}
                  onQualificationSelect={setSelectedQualifications}
                />
              ),
            },
          ]);
        } else {
          setPages((prev) => [
            {
              title: "Explanation",
              content: <Directions />,
              colorScheme: "primary",
            },
            {
              title: "HR Facts",
              content: (
                <SwiperFacts
                  data={data}
                  viaHR={!data?.self_invited}
                  onQualificationSelect={setSelectedQualifications}
                />
              ),
            },
          ]);
        }
        setJobAd(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const [pageIndex, setPageIndex] = useState(0);
  const handlePrevious = () => {
    setPageIndex(pageIndex - 1 >= 0 ? pageIndex - 1 : 0);
  };

  const handleNext = () => {
    setPageIndex(pageIndex + 1 < pages.length ? pageIndex + 1 : pageIndex);
  };

  return (
    <vacancyFlowContext.Provider
      value={{
        data: jobAd,
        setData: setJobAd,
      }}
    >
      <FullScreenComponent
        onPrevious={handlePrevious}
        onNext={handleNext}
        onDone={handleDone}
        currentIndex={pageIndex}
        totalIndex={pages.length - 1}
        logoSource={"https://logowik.com/content/uploads/images/pexels9456.jpg"}
        colorScheme={pages[pageIndex].colorScheme}
      >
        {pages[pageIndex].content}
      </FullScreenComponent>
    </vacancyFlowContext.Provider>
  );
};

export default VacancyFlowPage;
