import { CheckCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonLink from "../../../Components/Buttons/ButtonLink";
import Card from "../../../Components/Cards/Card";

const Created = () => {
  const { t } = useTranslation(["jobAd"], { keyPrefix: "create.steps.2" });
  return (
    <Card>
      <div className="flex justify-center items-center min-h-[500px]">
        <div className="text-center">
          <div className="flex justify-center mb-12">
            <CheckCircleIcon className="h-20 w-20 text-green-500" />
          </div>
          <h3 className="font-bold text-2xl">{t("headline")}</h3>
          <p className="whitespace-pre-line">{t("description")}</p>
          <div className="mt-12">
            <ButtonLink label={t("backButtonLabel")} href="/job-ads" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Created;
