import React from "react";
import PropTypes from "prop-types";
import ButtonLinkWithLeadingIcon from "../../Components/Buttons/ButtonLinkWithLeadingIcon";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const HRLayoutHeader = ({ title, linkLabel, linkHref, linkIcon }) => {
  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl">{title}</h1>
        {linkLabel && linkHref && (
          <ButtonLinkWithLeadingIcon
            label={linkLabel}
            href={linkHref}
            icon={linkIcon}
          />
        )}
      </div>
    </>
  );
};

export default HRLayoutHeader;

HRLayoutHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export const BackLink = ({ label, href }) => {
  return (
    <Link
      to={href}
      className="text-gray-400 hover:text-gray-600 flex items-center space-x-2 max-w-min whitespace-nowrap text-xs mb-2"
    >
      <ArrowLeftIcon className="h-4 w-4" aria-hidden="true" /> <p>{label}</p>
    </Link>
  );
};
