import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePersonas from "../../Api/usePersonas";
import TinderCard from "react-tinder-card";
import PersonaCard from "./PersonaComponents/PersonaCard";
import Spinner from "../../Components/Loaders/Spinner";
import Modal from "../../Components/Modals/Modal";
import { useTranslation } from "react-i18next";
import Button from "../../Components/Buttons/Button";
import Confetti from "react-dom-confetti";
import NarrowContainer from "../../Pages/Layouts/NarrowContainer";
import AnimatedWave from "../../Components/Animations/AnimatedWave";
import usePersonaImages from "../../Hooks/usePersonaImages";

const Vacancy = () => {
  let { hash } = useParams();
  const [persona, setPersona] = useState();
  const { getRandomPersona, swipePersona } = usePersonas();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [profile, setProfile] = useState(null);
  const [showHrModal, setShowHrModal] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation("persona", { keyPrefix: "success" });
  const { getPersonaImage } = usePersonaImages();

  useEffect(() => {
    getPersona();
  }, []);

  const getPersona = () => {
    getRandomPersona(hash).then((data) => {
      setLoading(false);
      if (data.job_expectations) {
        setShowModal(true);
        setProfile(data);
        return;
      }
      setPersona(data);
    });
  };

  const onCardLeftScreen = (direction) => {
    setLoading(true);
    swipePersona(hash, direction === "right" ? "liked" : "disliked").then(
      (data) => {
        getPersona();
      }
    );
  };

  if (persona && persona.job_expectations) {
    return (
      <>
        <NarrowContainer>
          <div className=" flex items-center justify-center min-h-screen">
            <div className="mt-4  pb-24 ">
              <PersonaCard
                persona={persona}
                imagePath={getPersonaImage(persona.gender)}
              />
            </div>
          </div>
        </NarrowContainer>
        <div className=" bg-em-primary fixed bottom-0 w-full ">
          <div className={`flex ${"justify-end"} py-4 px-4`}>
            <Button
              label={
                profile.self_invited
                  ? t("redirectButtonLabel")
                  : t("sendToHrButtonLabel")
              }
              onClick={
                profile.self_invited
                  ? () => navigate(`/job-ads/${profile.id_job_advertisement}`)
                  : () => setShowHrModal(true)
              }
            />
          </div>
        </div>
        <Modal show={showHrModal} onClose={() => setShowHrModal(false)}>
          <h3 className="font-bold text-sm">
            🚀{"    "} {t("hrModal.title")}
          </h3>
          <p className="text-sm mt-2 whitespace-pre-line">
            {t("hrModal.description")}
          </p>
        </Modal>
      </>
    );
  }
  return (
    <NarrowContainer>
      <div className="w-full h-4 absolute left-0 ">
        <div
          className="h-full bg-em-primary  rounded-r-full"
          style={{ width: persona ? (persona.step / 10) * 100 + "%" : "0%" }}
        />
      </div>
      <div className="px-2 mt-4 sm:mt-0 flex items-center justify-center h-screen ">
        <div>
          <div className="flex justify-center w-full">
            <Confetti
              active={showModal}
              config={{
                angle: 90,
                spread: 360,
                startVelocity: 25,
                elementCount: 300,
                dragFriction: 0.12,
                duration: 3000,
                stagger: 1,
                width: "10px",
                height: "10px",
                perspective: "500px",
                colors: ["#7879F1", "#CDCDFA", "#78E2F1", "#F17878"],
              }}
            />
          </div>
          {loading ? (
            <div className="flex justify-center items-center w-full h-full">
              <div className="animate-spin">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="relative z-50">
              <TinderCard
                className="z-50"
                key={persona?.name ?? "null"}
                onCardLeftScreen={onCardLeftScreen}
                preventSwipe={["up", "down"]}
              >
                <PersonaCard
                  persona={persona}
                  imagePath={getPersonaImage(persona?.gender)}
                  onReject={() => onCardLeftScreen("left")}
                  onAccept={() => onCardLeftScreen("right")}
                />
              </TinderCard>
            </div>
          )}
        </div>
        <div className="absolute w-screen bottom-0 left-0  opacity-40 z-0">
          <AnimatedWave speed={0.2} points={3} />
        </div>
        <div className="absolute w-screen bottom-0 left-0  opacity-60 z-0">
          <AnimatedWave animationDuration="4s" opacity={"0.8"} />
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setPersona(profile);
        }}
      >
        <h3 className="font-bold text-sm">
          🥳{"    "} {t("title")}
        </h3>
        <p className="text-sm mt-2 whitespace-pre-line">{t("description")}</p>
        <div className="mt-4 flex justify-end">
          <Button
            variant={"secondary"}
            label={t("buttonLabel")}
            onClick={() => {
              setShowModal(false);
              setPersona(profile);
            }}
          />
        </div>
      </Modal>
    </NarrowContainer>
  );
};

export default Vacancy;
