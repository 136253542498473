import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

const ButtonLink = ({ label, href }) => {
  return (
    <RouterLink
      to={href}
      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {label}
    </RouterLink>
  );
};

export default ButtonLink;

ButtonLink.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
