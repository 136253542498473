import React from "react";
import PropTypes from "prop-types";

const Button = ({ label, onClick, type, disabled, variant }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`rounded-3xl  px-7 py-2.5 text-sm font-bold  shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-em-primary ${
        disabled && "opacity-40"
      }
       ${
         variant === "secondary"
           ? "bg-em-primary text-white"
           : variant === "gray"
           ? "bg-gray-100 text-gray-400"
           : "bg-white text-em-primary"
       } `}
    >
      {label}
    </button>
  );
};

export default Button;

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  disabled: false,
  variant: "primary",
};
