import React from "react";
import HRLayout from "../Layouts/HRLayout";
import HRLayoutHeader from "../Layouts/HRLayoutHeader";
import Button from "../../Components/Buttons/Button";
import { useEffect } from "react";
import axios from "axios";
import SelectWithInsetLabel from "../../Components/FormElements/SelectWithInsetLabel";


import { useTranslation } from "react-i18next";
const Video = () => {
  const { t } = useTranslation("persona", { keyPrefix: "success" });

  
  const defaultTempalte = 2;
 
  
/*
  Du liebst React programmieren?
  Lust auf spannende Projekte?
  Wie dieses Video das durch eine KI generiert wurde?
  Dann bewirb dich jetzt bei uns!
*/


  const voicetext_array = [
    [
      "Du liebst React programmieren?",
      "Lust auf spannende Projekte?",
      "Wie dieses Video das durch eine KI generiert wurde?",
      "Bewirb dich bei uns in Reutlingen!",
    ],
    [
      "Wanted: Autowhisperer!",
      "Du hast ein Händchen für Motoren und Autos",
      "Bringe deine KFZ-Mechaniker-Künste bei uns zum Vorschein!",
      "Dann bewirb dich jetzt!",
    ],
    [
      "Du hast ein Herz für leckeres Essen und den perfekten Kaffee?",
      "Du bist das Talent zur Gastrofachkraft!",
      "Werde Teil unseres kulinarischen Zirkus!",
      "Bewirb dich jetzt!",
    ],
    [
      "Supermarkt-Style!",
      "Schließ dich unserem Einzelhandelsteam an.",
      "Rocke die Gänge mit deinem Charme und deinem Verkaufstalent.",
      "Faire Bezahlung für jeden. Lass uns kennen lernen!",
    ],
    [
      "Die Welt ist dein Baumarkt!",
      "Werde Teil unseres Einzelhandelsteams!",
      "Begib dich auf eine Reise voller Inspiration, Schrauben und Hammer-Momente.",
      "Wir warten auf deine Bewerbung!",
    ],
    [
      "Den Status quo hinterfragen, neue Wege gehen!",
      "Werde ein wichtiger Einflussfaktor als Business Manager.",
      "Lass deine visionären Konzepte Wirklichkeit werden.",
      "Bewirb dich jetzt und bereichere uns mit deinen Ideen.",
    ],
    [
      "Bringe deinen Vertriebsspirit zum Glühen!",
      "Werde unser Account Manager!",
      "Überzeuge und betreue Kunden mit deinem Charme und deiner Eloquenz.",
      "Wir freuen uns auf dein Lächeln!",
    ],
  ];





  const defaul_voicetext = voicetext_array[1];
  console.log(defaul_voicetext);
  
  //setze ein neuen state für den text in der textarea bentuze als default den defaul_voicetext und join ohne array umbrüche
  const [customText, setcustomText] = React.useState(defaul_voicetext.join("\n"));
  const [voicetext, setVoicetext] = React.useState(defaul_voicetext);
  const [finaltext, setFinaltext] = React.useState(defaul_voicetext.join("\n"));

  const [playButton, setPlayButton] = React.useState(false);
  const [pauseButton, setPauseButton] = React.useState(true);
  const [genButton, setGenButton] = React.useState(false);

  const templateOptions = [
    { value: "2", label: "Nature" },
    { value: "3", label: "Auto" },
    { value: "4", label: "Gastro" },
    { value: "5", label: "Einzelhandel" },
    { value: "6", label: "Handwerk" },
    { value: "7", label: "Young" },
    { value: "dev", label: "Entwicklung" },
  ];

  function handleSelectTemplate(e) {
    console.log(e.value);
    setTemplate(e.value);
  }

  function setTemplate(id){
    const iframeurl = "/video/template/"+id+"/index.html";

    var iframe = document.getElementById("videopreview");
    var url = iframe.src;
    var newUrl = url.split("?");
    newUrl = iframeurl + "?" + newUrl[1];
    console.log(newUrl);

    iframe.src = newUrl;
  


  }

  function generatePreview(){
    setPlayButton(true);
    setPauseButton(true);

    let textAreaData = customText.split("\n");
    let textArray = createTextData(textAreaData);
    setFinaltext(textArray);
    setTimingInIframeUrl(textArray);
    console.log(textArray);
    //replace all line breaks with nothing
    var textforaudio = customText.replace(/(\r\n|\n|\r)/gm, " ");
    generateAudio(textforaudio);
  }

  function createTextData(textAreaData){
    var parts = ["opener", "job","company", "cta"];
    var textWithTime = [];
    
    for(var i = 0; i < textAreaData.length; i++){
      var time = estimateSpeakingTime(textAreaData[i]);
      textWithTime.push({part:parts[i], text: textAreaData[i], time: time*1.45});
    }
    
    return textWithTime;
  }


  function setText(sectionName, text, time) {
      var iframe = document.getElementById("videopreview").contentWindow;
      if (iframe) {
          var previewSequenzText = iframe.document.querySelector("#" + sectionName + " .section_text");
          if (previewSequenzText) {
              previewSequenzText.textContent = `${text}`;

          }
      }
  }


  let iframe_is_set = false;

  function setTimingInIframeUrl(text){
    var iframe = document.getElementById("videopreview");
    var url = iframe.src;
    var newUrl = url.split("?")[0] + "?";
    newUrl = newUrl + "render&";
    for(var i = 0; i < text.length; i++){
      newUrl = newUrl + text[i].part + "=" + text[i].time + "&";
    }

    if(!iframe_is_set){
    console.log("Setze neue url");
    iframe.src = newUrl;
    iframe_is_set=true;
    console.log(newUrl);
    }
  }

    function setInIframe(text){
      for(var i = 0; i < text.length; i++){
        setText(text[i].part, text[i].text, text[i].time);
      }
    }


    function setText(sectionName, text, time) {
      var iframe = document.getElementById("videopreview").contentWindow;
      if (iframe) {
        var previewSequenzText = iframe.document.querySelector(
          "#" + sectionName + " .section_text"
        );
        if (previewSequenzText) {
          previewSequenzText.textContent = `${text}`;
        }
      }
    }



  function generateAudio(text){
      
      const headers = {
        'Accept': 'audio/mpeg',
        'Content-Type': 'application/json',
        'xi-api-key': '363329c1b3a2a7d8321a2a3d4956da00'
      };
    


    var data = {
                'text': text,
                'model_id': 'eleven_multilingual_v1',
                'voice_settings': {
                    'stability': 0.35,
                    'similarity_boost': 0.1
                }
            };
            
            
            axios({
                method: 'post',
                url: 'https://api.elevenlabs.io/v1/text-to-speech/VR6AewLTigWG4xSOukaG',
                headers: headers,
                data: data,
                responseType: 'arraybuffer'
            })
                .then(async function (response) {
                  const blob = new Blob([response.data], { type: 'audio/mpeg' });
                  const url = URL.createObjectURL(blob);

                  var voice = document.getElementById("voice");
                  voice.src = url;
                 
                  setPlayButton(false);
                  setPauseButton(false);
   

                })
                .catch(function (error) {
                  console.log(error);
                    console.log("mp3 was generated");
                })
                .catch(function (error) {
                    console.log(error);
                });

  }

  function estimateSpeakingTime(text) {
    var intercept = 0.6792452830188679;
    var coef = 0.02484277;
    var length = text.length;

    return intercept + coef * length;
}


  function playvideo() {
    const voice = document.getElementById("voice");
    const music = document.getElementById("music");

    setInIframe(finaltext);
    var iframe = document.getElementById("videopreview");
    iframe.contentWindow.postMessage('play', '*');

    console.log(iframe.contentWindow.openertime);

    voice.play();
    music.volume = 0.2;
    music.play();
    voice.addEventListener("ended", function() {
      music.pause();
    });

  }

  function pausevideo() {
    const voice = document.getElementById("voice");
    const music = document.getElementById("music");

    var iframe = document.getElementById("videopreview");
    iframe.contentWindow.postMessage('pause', '*');
    voice.pause();
    music.pause();
  }

  const iframeStyle = {
    width: '540px',
    height: '960px',
    overflow: 'hidden',
  };




  return (
    <>
      <HRLayoutHeader title="Video" />
    <textarea value={customText} id="customtext" style={{width:"100%", height:"150px"}} onChange={(e) => setcustomText(e.target.value)} 
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    ></textarea>
    <div className="mt-5 mb-5">
          <Button
            variant={"secondary"}
            label={t("play")}
            disabled={playButton}
            onClick={() => {
              playvideo();
            }}
          />

        <Button
            variant={"secondary"}
            label={t("pause")}
            disabled={pauseButton}
            onClick={() => {
              pausevideo();
            }}
          />
          


        <Button
            variant={"secondary"}
            label={t("generatePreview")}
            disabled={genButton}
            onClick={() => {
              generatePreview();
            }}
          />

<div className="mt-5 mb-5"></div>

          <SelectWithInsetLabel
              name="template"
              label={t("selectTempalte")}
              options={templateOptions}
              onSelect={handleSelectTemplate}
            />


          </div>
   
      <div style={{width:'540px', height:'960px', overflow:'hidden'}}>
        <iframe id="videopreview" src="/video/template/dev/index.html?render" style={{width:1080, height:1920, transform: 'scale(0.5)', transformOrigin: 'top left',}} frameBorder="1"></iframe>
      </div>
      <audio id="voice" style={{display:'none'}} src="/tmp/media/voice_dev.mp3" controls/>
      <audio id="music" style={{display:'none'}} src="/tmp/media/music.mp3" controls/>
    </>
  );
};

export default Video;
