import React from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const Table = ({ data, columns, frameworkComponents }) => {
  const handleGridLoad = (grid) => {
    grid.api.sizeColumnsToFit();
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
  };

  const columnTypes = {
    dateColumn: {
      filter: "agDateColumnFilter",
    },
  };

  return (
    <div className="ag-theme-alpine  h-full">
      <AgGridReact
        components={frameworkComponents}
        style={{ width: "100%", height: "100%;" }}
        pagination={true}
        rowData={data}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        animateRows={true}
        onGridReady={handleGridLoad}
        columnTypes={columnTypes}
      ></AgGridReact>
    </div>
  );
};

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};
