import React, { useMemo } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Dropdown from "../../Components/Dropdowns/Dropdown";
import DropdownCellRenderer from "./DropdownCellRenderer";
import { Link, useNavigate } from "react-router-dom";

const useTableColumns = (options) => {
  const { t } = useTranslation(["jobAd"]);
  const navigate = useNavigate();

  const columnDefs = useMemo(
    () => [
      {
        field: "job_title",
        headerName: t("index.tableColumns.title"),
        filter: true,
      },
      {
        field: "working_type",
        headerName: t("index.tableColumns.workingType"),
        valueGetter: ({ data }) => {
          return t("index.tableData.workingType." + data.working_type);
        },
      },
      {
        field: "degree",
        headerName: t("index.tableColumns.degree"),
      },
      {
        field: "experience",
        headerName: t("index.tableColumns.experience"),
        valueGetter: ({ data }) => {
          return data.experience
            ? t("yearWithCount", { count: data.experience, ns: "common" })
            : "-";
        },
      },
      {
        field: "working_location",
        headerName: t("index.tableColumns.workingLocation"),
        valueGetter: ({ data }) => {
          return t("index.tableData.workingLocation." + data.working_location);
        },
      },
      { field: "", headerName: t("index.tableColumns.status") },
      {
        field: "created_at",
        valueGetter: ({ data }) => {
          return moment(data.created_at).format("DD.MM.YYYY HH:mm") + " Uhr";
        },
        type: "dateColumn",
        headerName: t("index.tableColumns.createdAt"),
      },
      {
        field: "id",
        type: "rightAligned",
        cellRenderer: ButtonCellRenderer,

        headerName: "",
        sortable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return { columnDefs };
};

export default useTableColumns;

const ButtonCellRenderer = (props) => {
  const navigate = useNavigate();
  return (
    <div className="px-2">
      <button
        className="text-em-primary bg-white hover:text-white hover:bg-em-primary px-2"
        onClick={() => {
          navigate(props.data.id.toString());
          console.log("tmp");
        }}
      >
        Create Job Ad
      </button>
    </div>
  );
};
