import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputWithInsetLabel from "../../../Components/FormElements/InputWithInsetLabel";
import TextareaWithInsetLabel from "../../../Components/FormElements/TextareaWithInsetLabel";
import Button from "../../../Components/Buttons/Button";
import CircularIconButton from "../../../Components/Buttons/CircularIconButton";
import { PlusIcon } from "@heroicons/react/24/solid";
import Card from "../../../Components/Cards/Card";
import SelectWithInsetLabel from "../../../Components/FormElements/SelectWithInsetLabel";
import { useTranslation } from "react-i18next";
import BadgeWithRemoveButton from "../../../Components/Badges/BadgeWithRemoveButton";

const JobAdForm = ({
  jobAd,
  submitLabel,
  loading,
  onChange,
  onSubmit,
  requiredErrors,
  deletable,
  onDelete,
  deleteLabel,
}) => {
  const { t } = useTranslation(["jobAd", "languages"]);
  const translationPrefix = "create.steps.0.form.";

  const handleCreateUserInput = (e) => {
    onChange({
      ...jobAd,
      parameters: [...jobAd.parameters, { key: "", value: "" }],
    });
  };

  const handleChange = (key, value) => {
    onChange({ ...jobAd, [key]: value });
  };
  const handleSelectLanguage = (language) => {
    if (jobAd?.languages.some((l) => l.value === language.value)) return;
    onChange({ ...jobAd, languages: [...jobAd?.languages, language] });
  };

  const handleParameterChange = (type, index, newValue) => {
    const newParameters = [...jobAd.parameters];
    newParameters[index][type] = newValue;
    onChange({ ...jobAd, parameters: newParameters });
  };

  const workingLocationOptions = [
    {
      label: t(translationPrefix + "workingLocation.options.local"),
      value: "local",
    },
    {
      label: t(translationPrefix + "workingLocation.options.remote"),
      value: "remote",
    },
    {
      label: t(translationPrefix + "workingLocation.options.hybdrid"),
      value: "hybrid",
    },
  ];

  const degreeOptions = [
    {
      label: t(translationPrefix + "degree.options.none"),
      value: "none",
    },
    {
      label: t(translationPrefix + "degree.options.bachelor"),
      value: "bachelor",
    },
    {
      label: t(translationPrefix + "degree.options.master"),
      value: "master",
    },
    {
      label: t(translationPrefix + "degree.options.doctor"),
      value: "master",
    },
  ];

  const languageOptions = Object.keys(
    t("languages", { ns: "languages", returnObjects: true })
  )
    .map((key) => ({
      label: t(`languages.${key}`, { ns: "languages" }),
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Card>
      <form className="space-y-4 " onSubmit={onSubmit}>
        <InputWithInsetLabel
          requiredError={requiredErrors.includes("job_title")}
          value={jobAd?.job_title}
          label={t(translationPrefix + "jobTitle.label")}
          placeholder={t(translationPrefix + "jobTitle.placeholder")}
          name="job_title"
          onChange={(value) => handleChange("job_title", value)}
          required
        />
        <TextareaWithInsetLabel
          requiredError={requiredErrors.includes("description")}
          value={jobAd?.description}
          label={t(translationPrefix + "description.label")}
          placeholder={t(translationPrefix + "description.placeholder")}
          name="description"
          onChange={(value) => handleChange("description", value)}
        />
        <div className="sm:grid grid-cols-2 gap-6 space-y-4 sm:space-y-0">
          <div>
            <SelectWithInsetLabel
              defaultIndex={jobAd?.working_type === "full_time" ? 0 : 1}
              onSelect={(value) => handleChange("working_type", value.value)}
              name="working_type"
              label={t(translationPrefix + "workingHours.label")}
              options={[
                {
                  label: t(translationPrefix + "workingHours.options.fullTime"),
                  value: "full_time",
                },
                {
                  label: t(translationPrefix + "workingHours.options.partTime"),
                  value: "part_time",
                },
              ]}
            />
          </div>
          <InputWithInsetLabel
            requiredError={requiredErrors.includes("working_hours")}
            value={jobAd?.working_hours}
            label={t(translationPrefix + "workingHoursInH.label")}
            type="number"
            name="working_hours"
            onChange={(value) => handleChange("working_hours", value)}
            placeholder={"40"}
            required
          />
        </div>
        <div className="sm:grid grid-cols-2 gap-6 space-y-4 sm:space-y-0">
          <div>
            <SelectWithInsetLabel
              defaultIndex={
                jobAd && jobAd.degree
                  ? degreeOptions.findIndex(
                      (option) => option.value === jobAd.degree
                    )
                  : 0
              }
              onSelect={(value) => handleChange("degree", value.value)}
              name="degree"
              label={t(translationPrefix + "degree.label")}
              options={degreeOptions}
            />
          </div>
          <InputWithInsetLabel
            requiredError={requiredErrors.includes("experience")}
            value={jobAd?.experience}
            label={t(translationPrefix + "experience.label")}
            type="number"
            name="experience"
            placeholder={"2"}
            onChange={(value) => handleChange("experience", value)}
            required
          />
        </div>
        <SelectWithInsetLabel
          defaultIndex={
            jobAd?.working_location
              ? workingLocationOptions.findIndex(
                  (option) => option.value === jobAd.working_location
                )
              : 0
          }
          onSelect={(value) => handleChange("working_location", value.value)}
          name="working_location"
          label={t(translationPrefix + "workingLocation.label")}
          options={workingLocationOptions}
        />
        <div className="sm:grid grid-cols-2 gap-6 space-y-4 sm:space-y-0">
          <div>
            <SelectWithInsetLabel
              name="languages"
              label={t(translationPrefix + "languages.label")}
              options={languageOptions}
              onSelect={handleSelectLanguage}
            />
          </div>
          <div className=" space-x-4">
            {jobAd?.languages &&
              jobAd.languages.map((language) => (
                <BadgeWithRemoveButton
                  key={language.value}
                  onRemove={() =>
                    onChange({
                      ...jobAd,
                      languages: jobAd.languages.filter(
                        (l) => l.value !== language.value
                      ),
                    })
                  }
                  label={language.label}
                  color="bg-indigo-50 text-indigo-700"
                  hoverColor={"hover:bg-indigo-600/20"}
                />
              ))}
            {(jobAd.languages.length === 0 ||
              requiredErrors.includes("languages")) && (
              <div className="flex items-center h-full">
                {requiredErrors.includes("languages") ? (
                  <p className="text-sm text-red-500">
                    {t(translationPrefix + "languages.requiredError")}
                  </p>
                ) : (
                  <p className="text-sm text-gray-500">
                    {t(translationPrefix + "languages.none")}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>

        <h3 className="text-sm text-gray-500 pt-4">
          {t(translationPrefix + "defaultInput.description")}
        </h3>
        <div className="">
          {jobAd?.parameters &&
            jobAd.parameters.map((p, i) => (
              <div className="sm:grid grid-cols-2 gap-6 space-y-4 sm:space-y-0 border-t border-em-primary first:border-none mt-4 pt-4 first:pt-0  ">
                <InputWithInsetLabel
                  label={t(translationPrefix + "defaultInput.key.label")}
                  value={p.key}
                  onChange={(value) => handleParameterChange("key", i, value)}
                  name={`key_${i}`}
                  placeholder={t(
                    translationPrefix + "defaultInput.key.placeholder"
                  )}
                />
                <InputWithInsetLabel
                  value={p.value}
                  label={t(translationPrefix + "defaultInput.value.label")}
                  name={`value_${i}`}
                  onChange={(value) => handleParameterChange("value", i, value)}
                  placeholder={t(
                    translationPrefix + "defaultInput.value.placeholder"
                  )}
                />
              </div>
            ))}
        </div>
        <CircularIconButton
          icon={<PlusIcon />}
          onClick={handleCreateUserInput}
        />
        <div className="flex space-x-4 justify-end mt-4 ">
        {deletable && (
          <div className="flex justify-end mt-4 ">
            <Button
              label={deleteLabel}
              type="submit"
              variant={"gray"}
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }}
            />
          </div>
        )}
        {submitLabel && (
          <div className="flex justify-end mt-4 ">
            <Button
              label={submitLabel}
              type="submit"
              variant={"secondary"}
              disabled={loading}
            />
          </div>
        )}
        </div>
      </form>
    </Card>
  );
};

export default JobAdForm;

JobAdForm.propTypes = {
  requiredErrors: PropTypes.arrayOf(PropTypes.string),
  jobAd: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

JobAdForm.defaultProps = {
  requiredErrors: [],
};
