import React, { useContext } from "react";
import AuthLayout from "./AuthLayout";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import useAuth from "../../Api/useAuth";
import AnimatedWave from "../../Components/Animations/AnimatedWave";
import useUser from "../../Api/useUser";

const Login = () => {
  const { dispatchAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { apiLogin } = useAuth();
  const { getUser } = useUser();

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      apiLogin(credentialResponse.access_token).then((response) => {
        if (response?.status) {
          getUser(response.token).then((user) => {
            dispatchAuth({
              type: "LOGIN",
              payload: response.token,
              user: user,
            });
            navigate("/dashboard");
          });
        } else {
          console.log("Login Failed");
        }
      });
    },
  });

  return (
    <AuthLayout>
      <div className="mt-10">
        <h4 className="mb-12">Welcome to the future of HR!</h4>
        <button
          className="border border-gray-200 rounded p-2"
          onClick={() => login()}
        >
          login with google
        </button>
        <div className="absolute w-screen bottom-0 left-0  opacity-40 z-0">
          <AnimatedWave speed={0.2} points={3} />
        </div>
        <div className="absolute w-screen bottom-0 left-0  opacity-60 z-0">
          <AnimatedWave animationDuration="4s" opacity={"0.8"} />
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
