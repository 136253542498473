import React from "react";
import HRLayout from "../Layouts/HRLayout";
import HRLayoutHeader from "../Layouts/HRLayoutHeader";
import Button from "../../Components/Buttons/Button";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
const Video = () => {
  const { t } = useTranslation("persona", { keyPrefix: "success" });

  

  function playvideo() {
    var iframe = document.getElementById("videopreview");
    iframe.contentWindow.postMessage('play', '*');

    var voice = document.getElementById("voice");
    voice.play();

    var music = document.getElementById("music");
    music.volume = 0.2;
  
    music.play();

  }

  function pausevideo() {
    var iframe = document.getElementById("videopreview");
    iframe.contentWindow.postMessage('pause', '*');

    var voice = document.getElementById("voice");
    voice.pause();

    var music = document.getElementById("music");
    music.pause();
  }

  const iframeStyle = {
    width: '540px',
    height: '960px',
    overflow: 'hidden',
  };

  return (
    <>
      <HRLayoutHeader title="Video" />

      <Button
            variant={"secondary"}
            label={t("play")}
            onClick={() => {
              playvideo();
            }}
          />

        <Button
            variant={"secondary"}
            label={t("pause")}
            onClick={() => {
              pausevideo();
            }}
          />
          
      <div style={{width:'540px', height:'960px', overflow:'hidden'}}>
        <iframe id="videopreview" src="/video/template/2/index.html?render" style={{width:1080, height:1920, transform: 'scale(0.5)', transformOrigin: 'top left',}} frameBorder="1"></iframe>
      </div>
      <audio id="voice" style={{display:'none'}} src="/tmp/media/voice.mp3" controls/>
      <audio id="music" style={{display:'none'}} src="/tmp/media/music.mp3" controls/>
    </>
  );
};

export default Video;
