import React, { useEffect, useState } from "react";
import HRLayout from "../Layouts/HRLayout";
import HRLayoutHeader from "../Layouts/HRLayoutHeader";
import { useTranslation } from "react-i18next";
import useJobAds from "../../Api/useJobAds";
import ListView from "../../Components/ListView";
const Dashboard = () => {

  // get user from localstorage
  const {first_name} = JSON.parse(localStorage.getItem("user"));

  const { t } = useTranslation(['common', "swiperFacts"]);

  const { getJobAds } = useJobAds();

  const [jobAds, setJobAds] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    getJobAds(token).then((data) => {
      setJobAds(data);
    });
  }, []);

  return (
    <>
      <HRLayoutHeader title={t('Hello') + ' ' + first_name + "!"} />
      <div className="grid grid-rows-3 space-between grid-cols-1">
        <div className="">
          <div className="text-2xl text-em-light-gray">Recent Vacancies</div>
          <ListView data={
              jobAds.sort(function(a,b){
                return new Date(b.created_at) - new Date(a.created_at);
              }).slice(0, 5)
            }
          />
        </div>
      </div>
    </>
  );
};
export default Dashboard;
