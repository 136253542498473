import axios from "axios";

const useJobAds = () => {
  const defaultJobAd = {
    languages: [],
    parameters: [],
    recipients: [],
    working_type: "full_time",
    degree: "none",
    working_location: "local",
  };
  const verifyData = (jobAd) => {
    let errors = [];
    if (!jobAd.job_title) {
      errors.push("job_title");
    }
    if (!jobAd.description) {
      errors.push("description");
    }
    if (!jobAd.languages || jobAd.languages.length === 0) {
      errors.push("languages");
    }
    if (!jobAd.working_hours) {
      errors.push("working_hours");
    }
    if (!jobAd.experience) {
      errors.push("experience");
    }
    console.log(errors);
    return errors;
  };

  const createJobAd = (data, token) => {
    let jobAdData = {
      ...data,
      languages: data.languages ? data.languages.map((l) => l.value) : [],
      parameters: data.parameters
        ? data.parameters.map((p) => ({
            [p.key]: p.value,
          }))
        : [],
    };
    return axios
      .post(
        `${process.env.REACT_APP_EMPOLO_URL}/job-advertisements`,
        jobAdData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getJobAds = (token) => {
    return axios
      .get(`${process.env.REACT_APP_EMPOLO_URL}/job-advertisements`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getJobAd = (token, id) => {
    return axios
      .get(`${process.env.REACT_APP_EMPOLO_URL}/job-advertisements/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteJobAd = (token, id) => {
    return axios
      .delete(`${process.env.REACT_APP_EMPOLO_URL}/job-advertisements/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateJobAd = (token, id, data) => {
    return axios
      .put(
        `${process.env.REACT_APP_EMPOLO_URL}/job-advertisements/${id}`,
        { ...data },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createJobOffer = (token, id) => {
    return axios
      .get(`${process.env.REACT_APP_EMPOLO_URL}/generate-job-offer/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateJobOffer = (token, id, markdown) => {
    return axios
      .put(
        `${process.env.REACT_APP_EMPOLO_URL}/job-offers/${id}`,
        { job_offer: markdown },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadJobOffer = (token, id) => {
    return axios
      .get(
        `${process.env.REACT_APP_EMPOLO_URL}/job-offer/${id}/generate-pdf?id=${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        var link = document.createElement("a");
        link.download = "job_advertisement.pdf";
        link.href = response.data.url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    createJobAd,
    getJobAds,
    getJobAd,
    updateJobAd,
    deleteJobAd,
    defaultJobAd,
    verifyData,
    createJobOffer,
    updateJobOffer,
    downloadJobOffer,
  };
};

export default useJobAds;
