import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Buttons/Button";
import VacancyLayout from "../Layouts/VacancyLayout";
const Directions = ({ showLayout }) => {
  const { t } = useTranslation("vacancy", { keyPrefix: "directions" });
  const navigate = useNavigate();

  if (showLayout) {
    return (
      <VacancyLayout
        colorScheme="primary"
        onSubmit={() => navigate("create")}
        submitButtonLabel={t("buttonLabel")}
      >
        <div className="text-white text-sm whitespace-pre-line  ">
          <p>{t("description")}</p>
          <div className="space-y-12 mt-12">
            {Array(3)
              .fill(0)
              .map((_, index) => (
                <div>
                  <h2 className="font-bold text-base">
                    {t(`${index + 1}.title`)}
                  </h2>
                  <p className="mt-2 font-medium">
                    {t(`${index + 1}.description`)}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </VacancyLayout>
    );
  }
  return (
    <>
      <div className="text-white text-sm whitespace-pre-line  ">
        <p>{t("description")}</p>
        <div className="space-y-12 mt-12">
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div>
                <h2 className="font-bold text-base">
                  {t(`${index + 1}.title`)}
                </h2>
                <p className="mt-2 font-medium">
                  {t(`${index + 1}.description`)}
                </p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Directions;
