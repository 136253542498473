import React from "react";

const ClickableBadge = ({ onClick, label, active }) => {
  const className = `inline-flex items-center rounded-full text-base px-6 py-3 text-xs font-medium text-em-light-gray ${
    active ? "bg-em-primary text-white" : "bg-em-dark-gray"
  }`;

  if (onClick) {
    return (
      <button className={className} onClick={onClick}>
        {label}
      </button>
    );
  }
  return <span className={className}>{label}</span>;
};

export default ClickableBadge;
