import React, { useEffect, useState } from "react";
const PersonaImages = () => {
  const [maleImages, setMaleImages] = useState([]);
  const [femaleImages, setFemaleImages] = useState([]);
  function importImages(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  useEffect(() => {
    setFemaleImages(
      Object.values(
        importImages(
          require.context(
            "../images/personas/female",
            false,
            /\.(png|jpe?g|svg)$/
          )
        )
      )
    );

    setMaleImages(
      Object.values(
        importImages(
          require.context(
            "../images/personas/male",
            false,
            /\.(png|jpe?g|svg)$/
          )
        )
      )
    );
  }, []);

  const getPersonaImage = (gender) => {
    return gender === 0
      ? femaleImages.at(Math.floor(Math.random() * femaleImages.length))
      : maleImages.at(Math.floor(Math.random() * maleImages.length));
  };

  return { getPersonaImage };
};

export default PersonaImages;
