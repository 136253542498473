import React from "react";

const Panels = ({ children, activePanel, onClick }) => {
  return (
    <nav aria-label="Progress">
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {children.map((child, index) =>
          React.cloneElement(child, {
            key: index,
            ...child.props,
            status:
              activePanel === index
                ? "current"
                : activePanel > index
                ? "complete"
                : "pending",
            showArrow: index !== children.length - 1,
            stepIdx: index,
            onClick: () => onClick(index),
          })
        )}
      </ol>
      <div className="mt-4">{children[activePanel].props.children}</div>
    </nav>
  );
};

export default Panels;
