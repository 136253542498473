import React, { useState } from "react";
import PropTypes from "prop-types";
import { PlusIcon } from "@heroicons/react/24/solid";
import Button from "../../../Components/Buttons/Button";
import CircularIconButton from "../../../Components/Buttons/CircularIconButton";
import Card from "../../../Components/Cards/Card";
import InputWithInsetLabel from "../../../Components/FormElements/InputWithInsetLabel";
import { useTranslation } from "react-i18next";

const InviteSwiperForm = ({ onSubmit, onBack }) => {
  const [userInputCount, setUserInputCount] = useState(1);
  const { t } = useTranslation(["jobAd"], { keyPrefix: "create.steps.1" });
  const handleCreateUserInput = () => {
    setUserInputCount((prev) => prev + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = [];
    const formElements = e.target.elements;
    for (const elementName in formElements) {
      const element = formElements[elementName];
      if (element instanceof HTMLInputElement && element.value !== "") {
        data.push(element.value);
      }
    }
    onSubmit([...new Set(data)]);
  };

  return (
    <Card>
      <div className="text-sm">{t("description")}</div>
      <form className="space-y-4 mt-4" onSubmit={handleSubmit}>
        {Array(userInputCount)
          .fill(0)
          .map((_, index) => (
            <InputWithInsetLabel
              key={index}
              label={t("form.email.label")}
              type="email"
              name={`email_${index}`}
              required={index === 0}
            />
          ))}

        <CircularIconButton
          icon={<PlusIcon />}
          onClick={handleCreateUserInput}
        />

        <div className="flex justify-end mt-4 space-x-4 ">
          <Button
            label={t("form.backButtonLabel")}
            type="button"
            onClick={onBack}
          />
          <Button label={t("form.submitButtonLabel")} type="submit" />
        </div>
      </form>
    </Card>
  );
};

export default InviteSwiperForm;

InviteSwiperForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};
