import React, { useEffect } from "react";
import HRLayoutHeader from "../Layouts/HRLayoutHeader";
import Button from "../../Components/Buttons/Button";
import axios from "axios";
import InputWithInsetLabel from "../../Components/FormElements/InputWithInsetLabel";
import Card from "../../Components/Cards/Card";
import { useTranslation } from "react-i18next";
import ButtonWithLeadingIcon from "../../Components/Buttons/ButtonWithLeadingIcon";
import toast from "react-hot-toast";
import SelectWithInsetLabel from "../../Components/FormElements/SelectWithInsetLabel";

import {
  ArrowDownTrayIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/24/solid";
import useVideoAd from "../../Api/useVideoAd";
const Video = () => {
  const { t } = useTranslation("jobAd", { keyPrefix: "createAd.video" });
  const { downloadVideo } = useVideoAd();
  const iframeurl = "/video/template/2/index.html?render";

  const defaul_voicetext = [
    "Hallo mein name ist Jochen.",
    "Komm doch zu uns ins Team!",
    "Hab Spaß bei uns.",
    "Komm jetzt und finde es raus in der Gruppe",
  ];

  const templateOptions = [
    { value: "2", label: "Nature" },
    { value: "3", label: "Auto" },
    { value: "4", label: "Gastro" },
    { value: "5", label: "Einzelhandel" },
    { value: "6", label: "Handwerk" },
    { value: "7", label: "Young" },
    { value: "dev", label: "Entwicklung" },
  ];



  const handleDownload = () => {};
  //setze ein neuen state für den text in der textarea bentuze als default den defaul_voicetext und join ohne array umbrüche

  const [voicetext, setVoicetext] = React.useState(defaul_voicetext);
  const [finaltext, setFinaltext] = React.useState(defaul_voicetext.join("\n"));

  const [playButton, setPlayButton] = React.useState(true);
  const [pauseButton, setPauseButton] = React.useState(true);
  const [genButton, setGenButton] = React.useState(false);

  const voice = document.getElementById("voice");
  const music = document.getElementById("music");

  const defaultTextArray =     [
    "Du liebst React programmieren?",
    "Lust auf spannende Projekte?",
    "Wie dieses Video das durch eine KI generiert wurde?",
    "Bewirb dich bei uns in Reutlingen!",
  ]

  function setTemplate(id){
    const iframeurl = "/video/template/"+id+"/index.html";

    var iframe = document.getElementById("videopreview");
    var url = iframe.src;
    var newUrl = url.split("?");
    newUrl = iframeurl + "?" + newUrl[1];
    console.log(newUrl);

    iframe.src = newUrl;
  

  }


  function handleSelectTemplate(e) {
    console.log(e.value);
    setTemplate(e.value);
  }


  function generatePreview(e) {
    toast.loading(t("toastLoading"));

    e.preventDefault();
    let inputValues = Object.values(Object.fromEntries(new FormData(e.target)));

    setPlayButton(true);
    setPauseButton(true);

    let textArray = createTextData(inputValues);
    setFinaltext(textArray);
    setTimingInIframeUrl(textArray);

    //replace all line breaks with nothing
    var textforaudio = inputValues.join(". ") + ".";
    console.log(textforaudio);
    generateAudio(textforaudio);
    toast.loading(t("toastLoading"));
  }

  function createTextData(textAreaData) {
    var parts = ["opener", "job", "company", "cta"];
    var textWithTime = [];

    for (var i = 0; i < textAreaData.length; i++) {
      var time = estimateSpeakingTime(textAreaData[i]);
      textWithTime.push({
        part: parts[i],
        text: textAreaData[i],
        time: time * 1.35,
      });
    }

    return textWithTime;
  }

  function setText(sectionName, text, time) {
    var iframe = document.getElementById("videopreview").contentWindow;
    if (iframe) {
      var previewSequenzText = iframe.document.querySelector(
        "#" + sectionName + " .section_text"
      );
      if (previewSequenzText) {
        previewSequenzText.textContent = `${text}`;
      }
    }
  }

  let iframe_is_set = false;

  function setTimingInIframeUrl(text) {
    var iframe = document.getElementById("videopreview");
    var url = iframe.src;
    var newUrl = url.split("?")[0] + "?";
    newUrl = newUrl + "render&";
    for (var i = 0; i < text.length; i++) {
      newUrl = newUrl + text[i].part + "=" + text[i].time + "&";
    }

    if (!iframe_is_set) {
      console.log("Setze neue url");
      iframe.src = newUrl;
      iframe_is_set = true;
      console.log(newUrl);
    }
  }

  function setInIframe(text) {
    for (var i = 0; i < text.length; i++) {
      setText(text[i].part, text[i].text, text[i].time);
    }
  }

  function setText(sectionName, text, time) {
    var iframe = document.getElementById("videopreview").contentWindow;
    if (iframe) {
      var previewSequenzText = iframe.document.querySelector(
        "#" + sectionName + " .section_text"
      );
      if (previewSequenzText) {
        previewSequenzText.textContent = `${text}`;
      }
    }
  }

  function generateAudio(text) {
    const headers = {
      Accept: "audio/mpeg",
      "Content-Type": "application/json",
      "xi-api-key": "363329c1b3a2a7d8321a2a3d4956da00",
    };

    var data = {
      text: text,
      model_id: "eleven_multilingual_v1",
      voice_settings: {
        stability: 0.35,
        similarity_boost: 0.1,
      },
    };

    axios({
      method: "post",
      url: "https://api.elevenlabs.io/v1/text-to-speech/VR6AewLTigWG4xSOukaG",
      headers: headers,
      data: data,
      responseType: "arraybuffer",
    })
      .then(async function (response) {
        const blob = new Blob([response.data], { type: "audio/mpeg" });
        const url = URL.createObjectURL(blob);

        var voice = document.getElementById("voice");
        voice.src = url;

        setPlayButton(false);
        setPauseButton(false);
        toast.success(t("toastSuccess"));
      })
      .catch(function (error) {
        toast.success(t("toastError"));

        console.log(error);
        console.log("mp3 was generated");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function estimateSpeakingTime(text) {
    var intercept = 0.6792452830188679;
    var coef = 0.02484277;
    var length = text.length;

    return intercept + coef * length;
  }

  function playvideo() {
    setInIframe(finaltext);
    var iframe = document.getElementById("videopreview");
    iframe.contentWindow.postMessage("play", "*");
    voice.play();
    music.volume = 0.2;
    music.play();
    voice.addEventListener("ended", function () {
      music.pause();
    });
  }

  function pausevideo() {
    var iframe = document.getElementById("videopreview");
    iframe.contentWindow.postMessage("pause", "*");
    voice.pause();
    music.pause();
  }

  return (
    <>
      <div>
        <p className="whitespace-pre-line">{t("description")}</p>
    <div className="mt-5"></div>
      <Card>
      <div className="mt-5">
              <SelectWithInsetLabel
                name="template"
                label={t("selectTemplate")}
                options={templateOptions}
                onSelect={handleSelectTemplate}
              />
            </div>
      </Card>

        <form className="space-y-4 mt-4" onSubmit={generatePreview}>
          <Card>
            <div className="space-y-4 mt-4">
              {Array(4)
                .fill(0)
                .map((_, i) => (
                  <InputWithInsetLabel
                    label={t(`input.${i}`)}
                    type="text"
                    name={`input${i}`}
                    required
                    defaultValue={defaultTextArray[i]}
                  />
                ))}
            </div>



          </Card>
          <div className="flex justify-end space-x-6">
            <Button
              type="submit"
              variant={"secondary"}
              label={t("generatePreview")}
              disabled={genButton}
            />
            <ButtonWithLeadingIcon
              icon={<PlayIcon />}
              variant={"secondary"}
              label={t("play")}
              disabled={playButton}
              onClick={() => {
                playvideo();
              }}
            />

            <ButtonWithLeadingIcon
              icon={<PauseIcon />}
              variant={"secondary"}
              label={t("pause")}
              disabled={pauseButton}
              onClick={() => {
                pausevideo();
              }}
            />

            <ButtonWithLeadingIcon
              disabled={pauseButton}
              variant={"secondary"}
              label={t("download")}
              icon={<ArrowDownTrayIcon />}
              onClick={handleDownload}
            />
          </div>
        </form>

        {/* <textarea
          value={customText}
          id="customtext"
          style={{ width: "100%", height: "150px" }}
          onChange={(e) => setcustomText(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        ></textarea> */}
        <div className="flex justify-center mt-12">
          <div style={{ width: "540px", height: "960px", overflow: "hidden" }}>
            <iframe
              title="videopreview"
              id="videopreview"
              src="/video/template/2/index.html?render"
              style={{
                width: 1080,
                height: 1920,
                transform: "scale(0.5)",
                transformOrigin: "top left",
              }}
              frameBorder="1"
            ></iframe>
          </div>
        </div>
        <audio
          id="voice"
          style={{ display: "none" }}
          src="/tmp/media/voice.mp3"
          controls
        />
        <audio
          id="music"
          style={{ display: "none" }}
          src="/tmp/media/music.mp3"
          controls
        />
      </div>
    </>
  );
};

export default Video;
