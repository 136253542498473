import React, { useContext, useEffect, useMemo, useState } from "react";
import HRLayoutHeader from "../Layouts/HRLayoutHeader";
import {
  EllipsisVerticalIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import Table from "../../Components/Table/Table";
import useJobAds from "../../Api/useJobAds";
import AuthContext from "../Auth/AuthContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useTableColumns from "./useTableColumns";
import Dropdown from "../../Components/Dropdowns/Dropdown";

const JobAds = () => {
  const { getJobAds } = useJobAds();
  const [jobAds, setJobAds] = useState([]);
  const { auth } = useContext(AuthContext);
  useEffect(() => {
    getJobAds(auth.token).then((response) => {
      setJobAds(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const { t } = useTranslation(["jobAd", "common"]);
  const { columnDefs } = useTableColumns();

  const dropdownRenderer = () => {
    const dropdownOptions = [{ label: "Edit" }];
    return (
      <Dropdown options={dropdownOptions}>
        <EllipsisVerticalIcon className="h-8 w-8" />{" "}
      </Dropdown>
    );
  };

  return (
    <>
      <HRLayoutHeader
        title={t("index.title")}
        linkHref={"create"}
        linkLabel={t("buttonLabels.create", { ns: "common" })}
        linkIcon={<PlusCircleIcon />}
      />
      <div className="h-screen">
        <Table
          data={jobAds}
          columns={columnDefs}
          frameWorkComponents={{ dropdown: dropdownRenderer }}
        />
      </div>
    </>
  );
};

export default JobAds;
