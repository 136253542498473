import React from "react";

const NarrowContainer = ({ children }) => {
  return (
    <div className=" px-4 sm:px-6 lg:px-8 ">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="mx-auto max-w-3xl h-full ">{children}</div>
    </div>
  );
};

export default NarrowContainer;
