import axios from "axios";

const usePersonas = () => {
  const getRandomPersona = (hash) => {
    return axios
      .get(`${process.env.REACT_APP_EMPOLO_URL}/persona-random/${hash}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const swipePersona = (hash, result) => {
    return axios
      .post(
        `${process.env.REACT_APP_EMPOLO_URL}/persona-random`,
        { hash: hash, result: result },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { getRandomPersona, swipePersona };
};

export default usePersonas;
