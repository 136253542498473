import React from "react";
import PropTypes from "prop-types";
import Wave from "react-wavify";

const AnimatedWave = ({ speed, color, points }) => {
  return (
    <Wave
      fill={color}
      paused={false}
      options={{
        height: 20,
        amplitude: 30,
        speed: speed,
        points: points,
      }}
    />
  );
};

export default AnimatedWave;

AnimatedWave.propTypes = {
  speed: PropTypes.number,
  color: PropTypes.string,
  points: PropTypes.number,
};

AnimatedWave.defaultProps = {
  speed: 0.15,
  color: "#7879F1",
  points: 4,
};
