import axios from "axios";
import React from "react";

const useVideoAd = () => {
  const downloadVideo = (token, id) => {
    return axios
      .get(
        `${process.env.REACT_APP_EMPOLO_URL}/job-offers-download/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { downloadVideo };
};

export default useVideoAd;
