import { CheckIcon } from "@heroicons/react/24/solid";
import React from "react";

const Panel = ({ status, title, onClick, stepIdx, showArrow }) => {
  if (status === "complete") {
    return (
      <>
        <li key={title} className="relative md:flex md:flex-1">
          <button onClick={onClick} className="group flex w-full items-center">
            <span className="flex items-center px-6 py-4 text-sm font-medium">
              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <span className="ml-4 text-sm font-medium text-gray-900">
                {title}
              </span>
            </span>
          </button>
          {showArrow && <Arrow />}
        </li>
      </>
    );
  }
  if (status === "current") {
    return (
      <>
        <li key={title} className="relative md:flex md:flex-1">
          <button
            onClick={onClick}
            className="flex items-center px-6 py-4 text-sm font-medium"
            aria-current="step"
          >
            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
              <span className="text-indigo-600">
                {" "}
                {stepIdx < 9 ? `0${stepIdx + 1}` : stepIdx + 1}
              </span>
            </span>
            <span className="ml-4 text-sm font-medium text-indigo-600">
              {title}
            </span>
          </button>
          {showArrow && <Arrow />}
        </li>{" "}
      </>
    );
  }

  return (
    <>
      <li key={title} className="relative md:flex md:flex-1">
        <button onClick={onClick} className="group flex items-center">
          <span className="flex items-center px-6 py-4 text-sm font-medium">
            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
              <span className="text-gray-500 group-hover:text-gray-900">
                {stepIdx < 9 ? `0${stepIdx + 1}` : stepIdx + 1}
              </span>
            </span>
            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
              {title}
            </span>
          </span>
        </button>
        {showArrow && <Arrow />}
      </li>
    </>
  );
};

const Arrow = () => {
  return (
    <div
      className="absolute right-0 top-0 hidden h-full w-5 md:block"
      aria-hidden="true"
    >
      <svg
        className="h-full w-full text-gray-300"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M0 -2L20 40L0 82"
          vectorEffect="non-scaling-stroke"
          stroke="currentcolor"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Panel;
