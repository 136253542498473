import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import VacancyLayout from "../Layouts/VacancyLayout";
import useJobAds from "../../../src/Api/useJobAds";

import JobAdForm from "../../Pages/JobAds/JobAdsComponents/JobAdForm";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Auth/AuthContext";

const Vacancy = () => {
  const { t } = useTranslation("vacancy", { keyPrefix: "create" });
  const { auth } = useContext(AuthContext);
  const { createJobAd, defaultJobAd, verifyData } = useJobAds();
  const [jobAd, setJobAd] = useState(defaultJobAd);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);

  const handleSubmit = () => {
    let errors = verifyData(jobAd);
    if (errors.length === 0) {
      createJobAd({ ...jobAd, self_swipe: true }, auth.token).then(
        (response) => {
          if (response.success) {
            navigate("/vacancy/" + response.data.hash);
          }
        }
      );
    } else {
      setErrors(errors);
    }
  };

  return (
    <VacancyLayout
      colorScheme={"secondary"}
      submitButtonLabel={t("submitButtonLabel")}
      onSubmit={handleSubmit}
    >
      <>
        <h2 className="font-bold text-base mb-4">{t("title")}</h2>
        <div className="-mx-4">
          <JobAdForm
            jobAd={jobAd}
            onChange={setJobAd}
            requiredErrors={errors}
          />
        </div>
      </>
    </VacancyLayout>
  );
};

export default Vacancy;
