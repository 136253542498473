import React from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../Components/Arrow/Index";
import Button from "../../Components/Buttons/Button";
import NarrowContainer from "../Layouts/NarrowContainer";
import VacancyLayout from "../Layouts/VacancyLayout";

const FullScreenComponent = ({
  children,
  onPrevious,
  onDone,
  onNext,
  logoSource,
  currentIndex,
  totalIndex,
  colorScheme,
}) => {
  const { t } = useTranslation("vacancy");
  return (
    <VacancyLayout
      colorScheme={colorScheme}
      onSubmit={currentIndex < totalIndex ? onNext : onDone}
      submitButtonLabel={
        currentIndex < totalIndex && currentIndex !== 1
          ? t("directions.buttonLabel")
          : t("directions.redirectButtonLabel")
      }
    >
      {children}
    </VacancyLayout>
  );
};

export default FullScreenComponent;
