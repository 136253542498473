import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import HRLayoutHeader, { BackLink } from "../Layouts/HRLayoutHeader";

import useJobAds from "../../Api/useJobAds";
import AuthContext from "../Auth/AuthContext";
import Video from "../Video/Videofr";
import Tabs from "../../Components/Tabs/Tabs";
import CreateTextAd from "./JobAdsComponents/CreateTextAd";
import toast from "react-hot-toast";
const CreateAd = () => {
  const { t } = useTranslation(["jobAd"], { keyPrefix: "createAd" });

  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const { createJobOffer, updateJobOffer, downloadJobOffer } = useJobAds();
  const [jobOffer, setJobOffer] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDownload = (markdown) => {
    setLoading(true);
    toast.promise(
      updateJobOffer(auth.token, jobOffer.id, markdown).then((response) =>
        downloadJobOffer(auth.token, jobOffer.id)
          .then(setLoading(false))
          .catch(() => {
            setLoading(false);
          })
      ),
      {
        loading: t("textAd.update.toastLoading"),
        success: t("textAd.update.toastSuccess"),
        error: t("textAd.update.toastError"),
      }
    );
  };

  useEffect(() => {
    if (auth.token) {
      setLoading(true);
      createJobOffer(auth.token, id)
        .then((response) => {
          setLoading(false);
          setJobOffer(response.job_offers.at(-1));
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  const regenerateAd = () => {
    setLoading(true);
    toast.promise(
      createJobOffer(auth.token, id)
        .then((response) => {
          setLoading(false);
          setJobOffer(response.job_offers.at(-1));
        })
        .catch(() => {
          setLoading(false);
        }),
      {
        loading: t("textAd.toastLoading"),
        success: t("textAd.toastSuccess"),
        error: t("textAd.toastError"),
      }
    );
  };

  const tabOptions = [
    {
      label: "Text Ad",
      content: (
        <CreateTextAd
          createdMarkdown={jobOffer?.job_offer}
          onRefresh={regenerateAd}
          onDownload={handleDownload}
          loading={loading}
        />
      ),
    },
    { label: "Video Ad", content: <Video /> },
  ];
  return (
    <>
      <BackLink label={t("backlinkLabel")} href={`/job-ads/${id}`} />
      <HRLayoutHeader title={t("title")} />
      <Tabs options={tabOptions} />
    </>
  );
};

export default CreateAd;
