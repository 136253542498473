import { useEffect, useReducer } from "react";
import AuthContext from "./Pages/Auth/AuthContext";
import authReducer from "./Pages/Auth/AuthReducer";
import Router from "./Router";
import { Toaster } from "react-hot-toast";
function App() {
  const [auth, dispatchAuth] = useReducer(authReducer, {
    isAuthenticated: false,
    token: null,
  });

  useEffect(() => {
    dispatchAuth({ type: "AUTH" });
  }, []);

  return (
    <AuthContext.Provider value={{ auth, dispatchAuth }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "black",
            },
          },
        }}
      />
      <Router />
    </AuthContext.Provider>
  );
}

export default App;
