import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

export default function ListView({data}) {

  const { t } = useTranslation('swiperFacts')

  console.log(data)
  return (
    <ul role="list" className="divide-y divide-gray-100">
      { data.length > 0 && data.map((vacancy) => (
        <li key={vacancy.id} className="relative flex justify-between gap-x-6 py-5">
          <div className="flex gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={'https://api.dicebear.com/6.x/shapes/svg?seed='+vacancy.job_title} alt="" />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                <a href={`/job-ads/${vacancy.id}`}>
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {vacancy.job_title}
                </a>
              </p>
              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                <a href={`mailto:${"#"}`} className="relative truncate hover:underline">
                  {vacancy.description}
                </a>
              </p>
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">{t(`hardFacts.workingType.options.${vacancy.working_type}`)}</p>
              {vacancy.created_at ? (
                <p>
                  <span className="flex flex-row mt-1 mr-3 text-xs leading-5 text-gray-500">
                    {
                      vacancy.summary.recipients_finished === vacancy.summary.recipients_total && vacancy.summary.recipients_total > 0  ? (
                        <div className="flex-none h-full w-3.5 mt-1 mx-1 rounded-full bg-emerald-500/20 p-1">
                          <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                        </div>
                      ) : (
                        <div className="flex-none h-full w-3.5 mt-1 mx-1 rounded-full bg-red-300/20 p-1">
                          <div className="h-1.5 w-1.5 rounded-full bg-red-300" />
                        </div>
                      )
                    }
                    Finished swipes: <time className='mx-1' dateTime={vacancy.created_at}>{vacancy.summary.recipients_finished +" / "+ vacancy.summary.recipients_total}</time>
                  </span>
                  <span className="mt-1 text-xs leading-5 text-gray-500">
                    Created <time dateTime={vacancy.created_at}>{vacancy.created_at.split(' ')[0]}</time>
                  </span>
                </p>
              ) : null}
            </div>
            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </div>
        </li>
      ))}
    </ul>
  )
}