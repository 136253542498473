import React, { useContext, useState } from "react";
import Panel from "../../Components/Steps/Panel";
import Panels from "../../Components/Steps/Panels";
import HRLayoutHeader from "../Layouts/HRLayoutHeader";
import InviteSwiperForm from "./JobAdsComponents/InviteSwiperForm";
import JobAdForm from "./JobAdsComponents/JobAdForm";
import Created from "./JobAdsComponents/Created";
import useJobAds from "../../Api/useJobAds";
import AuthContext from "../Auth/AuthContext";
import { useTranslation } from "react-i18next";

const JobAd = () => {
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation(["jobAd"], { keyPrefix: "create" });
  const [step, setStep] = useState(0);
  const { createJobAd, defaultJobAd } = useJobAds();
  const [metaData, setMetaData] = useState(defaultJobAd);
  const handleSubmit = () => {
    setStep(1);
  };

  const handleSwiperInviteSubmit = (data) => {
    createJobAd({ ...metaData, recipients: data }, auth.token).then(
      (response) => {
        if (response?.success) {
          setStep(2);
        }
      }
    );
  };
  return (
    <>
      <HRLayoutHeader title={t("title")} />
      <Panels activePanel={step} onClick={() => {}}>
        <Panel title={t("steps.0.title")}>
          <JobAdForm
            onChange={setMetaData}
            onSubmit={handleSubmit}
            jobAd={metaData}
            submitLabel={t("steps.0.form.submitButtonLabel")}
          />
        </Panel>
        <Panel title={t("steps.1.title")}>
          <InviteSwiperForm
            onBack={() => setStep(0)}
            onSubmit={handleSwiperInviteSubmit}
          />
        </Panel>
        <Panel title={t("steps.2.title")}>
          <Created />
        </Panel>
      </Panels>
    </>
  );
};

export default JobAd;
