import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InputWithInsetLabel = ({
  label,
  type,
  name,
  placeholder,
  defaultValue,
  required,
  onChange,
  value,
  requiredError,
}) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <div
        className={`rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 bg-white ${
          requiredError && "border border-red-500 "
        }`}
      >
        <label
          htmlFor="name"
          className="block text-xs font-medium text-gray-900"
        >
          {label + (required ? "*" : "")}
        </label>
        <input
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e.target.value)}
          type={type}
          required={required}
          name={name}
          id={name}
          className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder={placeholder}
        />
      </div>{" "}
      {requiredError && (
        <p className="text-xs text-red-500 mt-1">{t("requiredErrorLabel")}</p>
      )}
    </div>
  );
};

export default InputWithInsetLabel;

InputWithInsetLabel.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

InputWithInsetLabel.defaultProps = {
  type: "text",
  required: false,
  onChange: () => {},
};
