import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useJobAds from "../../Api/useJobAds";
import Card from "../../Components/Cards/Card";
import AuthContext from "../Auth/AuthContext";
import HRLayoutHeader, { BackLink } from "../Layouts/HRLayoutHeader";
import JobAdForm from "./JobAdsComponents/JobAdForm";
import toast from "react-hot-toast";
import ProfileOverview from "./JobAdsComponents/ProfileOverview";
import ButtonWithLeadingIcon from "../../Components/Buttons/ButtonWithLeadingIcon";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const JobAd = () => {
  const { t } = useTranslation(["jobAd", "languages", "common"]);
  const [loading, setLoading] = useState(false);

  const { auth } = useContext(AuthContext);
  const { getJobAd, updateJobAd, defaultJobAd, verifyData, deleteJobAd } = useJobAds();
  const [jobAd, setJobAd] = useState(defaultJobAd);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    if (auth && auth.token) {
      getJobAd(auth.token, id).then((response) => {
        if(!response) {
          setErrors(["jobAd.notFound"]);
        }
        let languages = response.languages.map((l) => ({
          value: l.language,
          label: t("languages." + l.language, { ns: "languages" }),
        }));
        setJobAd({ ...response, languages: languages });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = verifyData(jobAd);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);
    let jobAdData = {
      ...jobAd,
      languages: jobAd.languages.map((l) => l.value),
      parameters: jobAd.parameters.map((p) => ({
        [p.key]: p.value,
      })),
    };
    toast.promise(
      updateJobAd(auth.token, id, jobAdData).then((response) => {
        setLoading(false);
      }),
      {
        loading: t("toastMessages.saving.loading", { ns: "common" }),
        success: t("toastMessages.saving.success", { ns: "common" }),
        error: t("toastMessages.saving.error", { ns: "common" }),
      }
    );
  };

  const handleDelete = () => {
    toast.promise(
      deleteJobAd(auth.token, id).then((response) => {
      }),
      {
        loading: t("toastMessages.deleting.loading", {
          ns: "common",
        }),
        success: t("toastMessages.deleting.success", {
          ns: "common",
        }),
        error: t("toastMessages.deleting.error", {
          ns: "common",
        }),
      }
    ).then(() => {
      navigate("/dashboard");
    });
  };

  return (
    <>
      <BackLink label={t("edit.backlinkLabel")} href="/job-ads" />
      <HRLayoutHeader
        title={t("edit.title")}
        linkHref="create"
        linkLabel={t("edit.createJobAdLabel")}
        linkIcon={<PlusCircleIcon />}
      />

      <div className="mt-2">
        <Card>
          <JobAdForm
            onChange={setJobAd}
            requiredErrors={errors}
            loading={loading}
            jobAd={jobAd}
            onSubmit={handleSubmit}
            submitLabel={t("edit.submitLabel")}
            deletable={true}
            onDelete={() => {
              console.log("delete");
              // show modal with confirmation
              // if confirmed, deleteJobAd and navigate to /dashboard
              handleDelete();
            }}
            deleteLabel={t("edit.deleteLabel")}
          />
        </Card>
      </div>
      <div className="mt-8 pt-8 border-t border-em-primary">
        <ProfileOverview profiles={jobAd?.profiles} summary={jobAd?.summary} />
      </div>
    </>
  );
};

export default JobAd;
