import React from "react";
import PropTypes from "prop-types";

const ButtonWithLeadingIcon = ({
  label,
  icon,
  type,
  variant,
  onClick,
  disabled,
}) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={`rounded-3xl  px-7 py-2.5 text-sm font-bold  shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-em-primary flex 
        ${
          variant === "secondary"
            ? "bg-em-primary text-white"
            : variant === "gray"
            ? "bg-gray-100 text-gray-400"
            : "bg-white text-em-primary"
        }

        ${disabled && "opacity-40"}
        `}
      >
        {React.cloneElement(icon, {
          className: "-ml-0.5 mr-2 h-5 w-5",
        })}
        {label}
      </button>
    </>
  );
};

export default ButtonWithLeadingIcon;

ButtonWithLeadingIcon.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  icon: PropTypes.element.isRequired,
};

ButtonWithLeadingIcon.defaultProps = {
  type: "button",
};
