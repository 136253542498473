import React from "react";
import { useTranslation } from "react-i18next";
import ClickableBadge from "../../../Components/Badges/ClickableBadge";

const HardFacts = ({ data }) => {
  const { t } = useTranslation(["swiperFacts", "languages"]);
  return (
    <div className="space-y-6 mt-6">
      <Hardfact title={t("hardFacts.jobTitle")}>
        <h2>{data.job_title}</h2>
      </Hardfact>
      <Hardfact title={t("hardFacts.workingType.label")}>
        <h2>{t("hardFacts.workingType.options." + data.working_type)}</h2>-
        <h2>
          {t("hardFacts.workingLocation.options." + data.working_location)}
        </h2>
      </Hardfact>
      <Hardfact title={t("hardFacts.degree.label")}>
        <h2>{t("hardFacts.degree.options." + data.degree)}</h2>-
        <h2>
          {t("hardFacts.experienceWithCount", {
            count: data.experience,
          })}
        </h2>
      </Hardfact>
      <Hardfact title={t("hardFacts.languages")}>
        {data.languages.map((language) => (
          <h2 key={language.language}>
            {t("languages." + language.language, { ns: "languages" })}
          </h2>
        ))}
      </Hardfact>
    </div>
  );
};

export default HardFacts;

const Hardfact = ({ title, children }) => {
  return (
    <div>
      <h3 className="text-em-light-gray">{title}</h3>
      <div className="mt-1 flex items-center gap-4 flex-wrap">{children}</div>
    </div>
  );
};
