import React from "react";
import { useTranslation } from "react-i18next";
import AttributeBatch from "./AttributeBatch";
import { HeartIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ReactComponent as PersonalityIcon } from "../../../images/icons/target-goal.svg";
import { ReactComponent as InterestIcon } from "../../../images/icons/sun-sea-sunrise.svg";
import { ReactComponent as RocketIcon } from "../../../images/icons/space-rocket.svg";
import { ReactComponent as XIcon } from "../../../images/icons/delete-disabled-1.svg";
import { ReactComponent as SuitcaseIcon } from "../../../images/icons/suitcase-portfolio-1.svg";
import { ReactComponent as CloudIcon } from "../../../images/icons/weather-cloud.svg";
import { ReactComponent as MobilePhoneIcon } from "../../../images/icons/mobile-devices-iphone-down-menu.svg";

const PersonaCard = ({ persona, onReject, onAccept, imagePath }) => {
  const { t } = useTranslation("persona");
  if (!persona) return <></>;
  return (
    <div
      className="rounded-3xl shadow-xl p-4 min-h-full w-full pt-4 text-sm border border-gray-200 max-w-[500px] z-50 bg-white"
      q
    >
      <div className="flex justify-center">
        <img src={imagePath} alt="persona" className="h-48 w-auto" />
      </div>
      <div className="flex items-center space-x-2 my-8">
        <h3 className="font-bold">{persona.name}</h3>
        <p className="">{persona.age}</p>
      </div>
      {/* <div className="flex flex-wrap items-center gap-2 py-6">
        {persona.gender ? (
          <AttributeBatch label={t(`gender.${persona.gender}`)} />
        ) : (
          <></>
        )}
        {persona.family ? <AttributeBatch label={persona.family} /> : <></>}
        {persona.children ? (
          <AttributeBatch
            label={t(`childrenWithCount`, { count: persona.children })}
          />
        ) : (
          <></>
        )}
        {persona.education_level ? (
          <AttributeBatch label={persona.education_level} />
        ) : (
          <></>
        )}
      </div> */}

      {persona.personality && (
        <AttributeSection label={t("personality")} icon={<PersonalityIcon />}>
          {Object.keys(persona.personality).map((personality) => {
            return (
              <AttributeBatch
                key={personality}
                label={persona.personality[personality].text}
              />
            );
          })}
        </AttributeSection>
      )}

      {persona.hobbies && (
        <AttributeSection label={t("interests")} icon={<InterestIcon />}>
          {persona.hobbies.map((hobby) => {
            return <AttributeBatch key={hobby} label={hobby} />;
          })}
        </AttributeSection>
      )}

      {persona.attributes && (
        <>
          {persona.attributes.positive && (
            <AttributeSection
              label={t("attributes.positive")}
              icon={<RocketIcon />}
            >
              {persona.attributes.positive.map((attr) => {
                return <AttributeBatch key={attr} label={attr} />;
              })}
            </AttributeSection>
          )}
          {persona.attributes.neutral && (
            <AttributeSection
              label={t("attributes.neutral")}
              icon={<CloudIcon />}
            >
              {persona.attributes.neutral.map((attr) => {
                return <AttributeBatch key={attr} label={attr} />;
              })}
            </AttributeSection>
          )}
          {persona.attributes.negative && (
            <AttributeSection label={t("attributes.negative")} icon={<XIcon />}>
              {persona.attributes.negative.map((attr) => {
                return <AttributeBatch key={attr} label={attr} />;
              })}
            </AttributeSection>
          )}
        </>
      )}

      {persona.intrests && (
        <AttributeSection label={t("interests")} icon={<InterestIcon />}>
          {persona.intrests.map((interest) => {
            return <AttributeBatch key={interest} label={interest} />;
          })}
        </AttributeSection>
      )}

      {persona.job_expectations && (
        <AttributeSection label={t("jobExpectations")} icon={<SuitcaseIcon />}>
          {persona.job_expectations.map((job) => {
            return <AttributeBatch key={job} label={job} />;
          })}
        </AttributeSection>
      )}

      {persona.plattforms && persona.job_expectations === "object" && (
        <AttributeSection label={t("plattforms")} icon={<CloudIcon />}>
          {Object.keys(persona.plattforms).map((plattform) => {
            return <AttributeBatch key={plattform} label={plattform} />;
          })}
        </AttributeSection>
      )}

      {!persona.attributes && (
        <div className="mt-8 flex items-center justify-between px-12 pb-6">
          <button
            className="pressable rounded-full bg-red-300 hover:bg-red-400 p-2 h-16 w-16 flex items-center justify-center"
            onClick={onReject}
          >
            <XMarkIcon className="h-8 w-8 text-white" />
          </button>
          <button
            className="pressable rounded-full bg-green-300 hover:bg-green-400 p-2 h-16 w-16 flex items-center justify-center"
            onClick={onAccept}
          >
            <HeartIcon className="h-8 w-8 text-white" />
          </button>
        </div>
      )}
    </div>
  );
};

export default PersonaCard;

const AttributeSection = ({ children, label, icon }) => {
  return (
    <div className={`border-t border-em-primary -mx-4`}>
      <div className="px-4 py-4">
        <div className="flex items-center space-x-4">
          {icon && React.cloneElement(icon, { className: "h-6 w-6" })}
          <h3 className="font-bold">{label}</h3>
        </div>
        <ul className="flex flex-wrap gap-x-4 gap-y-2 mt-4">{children} </ul>
      </div>
    </div>
  );
};
