import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClickableBadge from "../../Components/Badges/ClickableBadge";
import HardFacts from "./SwiperFactsComponents/HardFacts";

const SwiperFacts = ({ data, viaHR, onQualificationSelect }) => {
  const { t } = useTranslation(["swiperFacts"]);
  const [qualifications, setQualifications] = useState({});
  const [selectedQualifcations, setSelectedQualifcations] = useState([]);

  const handleSelectQualification = (id) => {
    if (selectedQualifcations.some((q) => q === id)) {
      setSelectedQualifcations(selectedQualifcations.filter((q) => q !== id));
    } else {
      setSelectedQualifcations([...selectedQualifcations, id]);
    }
  };

  useEffect(() => {
    onQualificationSelect(selectedQualifcations);
  }, [selectedQualifcations]);

  useEffect(() => {
    if (data && data.qualifications) {
      let tmp = [];

      data.qualifications.map((qualification) => {
        if (tmp[qualification.key]) {
          tmp[qualification.key].push({
            value: qualification.value,
            id: qualification.id,
          });
        } else {
          tmp[qualification.key] = [
            { value: qualification.value, id: qualification.id },
          ];
        }
      });
      setQualifications(tmp);
    }
  }, [data]);

  if (!data) return <></>;

  return (
    <>
      <h2 className="font-bold text-base  ">{t("title")}</h2>
      <p className="mt-2 text-sm whitespace-pre-line">{t("description")}</p>
      <HardFacts data={data} />
      {Object.keys(qualifications).length > 0 && (
        <div className="mt-6 border-t border-em-primary pt-6 pb-96">
          <h2 className="font-bold text-base">{t("qualifications.title")}</h2>
          <p className="text-sm whitespace-pre-line">
            {t("qualifications.description")}
          </p>
          <ul className="space-y-12 mt-6 ">
            {qualifications &&
              Object.keys(qualifications).map((key) => {
                return (
                  <li key={key}>
                    <h3 className="text-sm mb-4">{key}</h3>
                    <ul className="flex items-center gap-4 flex-wrap">
                      {qualifications[key].map((qualification) => {
                        return (
                          <ClickableBadge
                            key={qualification.id}
                            label={qualification.value}
                            active={selectedQualifcations.some(
                              (q) => q === qualification.id
                            )}
                            onClick={() =>
                              handleSelectQualification(qualification.id)
                            }
                          />
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
};

export default SwiperFacts;
