import React from "react";

import { useState } from "react";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ options }) => {
  return (
    <Tab.Group>
      <Tab.List className={"space-x-12 "}>
        {options.map((option) => (
          <Tab
            className={({ selected }) =>
              `border-b  hover:border-gray-300  ${
                selected ? "border-em-primary" : "border-transparent"
              }`
            }
          >
            {option.label}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className={"mt-6"}>
        {options.map((option) => {
          return <Tab.Panel>{option.content}</Tab.Panel>;
        })}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;
