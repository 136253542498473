import React from "react";
import PropTypes from "prop-types";

const CircularIconButton = ({ icon, onClick, type }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {React.cloneElement(icon, { className: "h-5 w-5" })}
    </button>
  );
};

export default CircularIconButton;

CircularIconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

CircularIconButton.defaultProps = {
  type: "button",
};
