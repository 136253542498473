import { ArrowPathIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import React, { Suspense } from "react";
import Button from "../../Components/Buttons/Button";
import Spinner from "../../Components/Loaders/Spinner";
import NarrowContainer from "./NarrowContainer";
import logo from "../../images/logo.png";

const VacancyLayout = ({
  children,
  submitButtonLabel,
  onBack,
  onSubmit,
  colorScheme,
  showIcon,
}) => {
  return (
    <>
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <div
          className={`pt-6 h-full relative  ${
            colorScheme === "primary" ? "bg-em-primary" : "bg-white"
          }`}
        >
          <NarrowContainer>
            <div className="sm:mt-32">
              {<img src={logo} alt="logo" className="h-6 w-auto mb-4" />}
              <div className=" h-full ">{children}</div>
            </div>
          </NarrowContainer>
          <div className="fixed bottom-0 w-screen">
            <div className="border-t border-white  px-6  sm:px-12 bg-em-primary py-6">
              <div
                className={`flex ${
                  onBack ? "justify-between" : "justify-end"
                } `}
              >
                {onBack && (
                  <button onClick={onBack}>
                    <ChevronLeftIcon className="h-5 w-5 text-white stroke-2" />{" "}
                  </button>
                )}
                <Button label={submitButtonLabel} onClick={onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default VacancyLayout;
